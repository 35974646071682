<template>
  <van-row class="model-list" gutter=20>
    <van-col class="model-label" span="24" :class="{invalid:modelId === 2 || btnInvalidAll}" @click="modelClick(2)" ><p>隐茶杯</p></van-col>
    <van-col class="model-label" span="24" :class="{invalid:modelId === 3 || btnInvalidAll}" @click="modelClick(3)"><p>经典保温杯</p></van-col>  
    <!-- <van-col class="model-label" span="24" :class="{invalid:modelId === 5 || btnInvalidAll}" @click="modelClick(5)"><p>黑色保温杯</p></van-col>   -->
  </van-row>
</template>

<script>

import { Col, Row, Tab, Tabs, Dialog } from 'vant';
import Vue from 'vue';


Vue.use( Col );
Vue.use( Row );
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);

export default {
  name: 'SelectModelPanel',
  data() {
    return {
      modelId:2,
      btnInvalidAll: false,
    }    
  },
  methods: {

    modelClick(modelId) {

      if( this.modelId === modelId) return
      this.modelId = modelId;
      this.$eventBus.$emit('switchModel', modelId)
    },
    
  },
  mounted(){
    if(this.$route.query.modelId >= 0) this.modelId = parseInt(this.$route.query.modelId);

	}, 
  created() {
    this.$eventBus.$on("modelLoading", () => this.btnInvalidAll = true);
    this.$eventBus.$on("modelLoadComplete", (modelId, modelType) => {
      this.btnInvalidAll = false;
      this.modelId = modelId;
    });
  }
 

}
</script>

<style lang="scss" scoped>

.model-list {

  padding: 20px 40px 10px;

  .model-label {
    cursor: pointer;

    &.invalid {
      pointer-events: none;
      // opacity: 0.3; 

      p {
        
        background-color: #1989fa;
        color: #ffff;
        border: 1px ;
      }
    }

    p {
      border: 1px solid #1989fa;
      color: #1989fa;
      background: none;
      line-height: 36px;
      text-align: center;
      border-radius: 18px;
      margin: 10px;
    }
  }

}


</style>
