import axios from 'axios';

const http = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 60 * 1000,
    baseURL:'https://api.shop.tczhilian.com/api',//生产环境
    // baseURL:'https://api.yinqu.tczhilian.com/api',//测试环境

})

export default  http;
